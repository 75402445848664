import { useState } from "react";

//Contex
import { useMaterialUIController, fetchConfig } from "context";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SpringModal from "examples/Modal";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import configTableData from "layouts/table_config/data/configTableData";

import axios from "axios";
import Cookies from "js-cookie";
import MDInput from "components/MDInput";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function TableConfig() {
  const [controller, dispatch] = useMaterialUIController();
  const rol = Cookies.get("user_p");

  const { config } = controller;

  const [deleteModal, setDeleteModal] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);
  const [configData, setConfig] = useState(null);
  const [dataForm, setDataForm] = useState({
    id: "",
    variable: "",
    value: "",
    status: "",
  });
  const [createUser, setCreateUser] = useState({
    variable: "",
    valor: "",
    status_id: 1,
  });

  const token = Cookies.get("access_token");

  const handleDeleteClick = (value) => {
    if (value?.status.name !== "Activo") return;
    setConfig(value);
    setDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await axios.delete(`/admin/config/${configData?.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      fetchConfig(dispatch);
      setDeleteModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditClick = (c) => {
    setConfig(c);

    const selectedStatus = config?.data?.status.find((status) => status.name === c?.status?.name);

    setDataForm({
      id: c?.id,
      variable: c?.variable,
      value: c?.valor,
      status: selectedStatus ? selectedStatus.id : "",
    });

    setModalEdit(true);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    setDataForm((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleCreateChange = (e) => {
    const { name, value } = e.target;

    setCreateUser((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleModalClose = () => {
    setDeleteModal(false);
    setModalEdit(false);
    setModalCreate(false);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.put(
        `/admin/config/${config.id}`,
        {
          id: dataForm.id,
          variable: dataForm.variable,
          valor: dataForm.value,
          status_id: dataForm.status,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        fetchConfig(dispatch);
        handleModalClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateUser = async () => {
    try {
      const response = await axios.post(
        "/admin/config",
        {
          variable: createUser.variable,
          valor: createUser.valor,
          status_id: createUser.status_id,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        fetchConfig(dispatch);
        handleModalClose();

        setCreateUser({
          variable: "",
          valor: "",
          status_id: 1,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { columns, rows } = configTableData(handleDeleteClick, handleEditClick);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Configuraciones
                </MDTypography>
              </MDBox>

              {rol === "1" ? (
                ""
              ) : (
                <MDBox display="flex" justifyContent="flex-start" mx={2} mt={2} py={2} px={1}>
                  <MDButton color="primary" size="small" onClick={() => setModalCreate(true)}>
                    +
                  </MDButton>
                </MDBox>
              )}

              <MDBox pt={3}>
                {rol === "1" ? (
                  <MDBox fullWidth display="flex" justifyContent="center">
                    <MDTypography py={3}>No tienes permiso para ver este modulo</MDTypography>
                  </MDBox>
                ) : (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={{ defaultValue: 10, entries: [10, 15, 20, 25] }}
                    showTotalEntries={false}
                    noEndBorder
                    pagination={{ variant: "gradient", color: "info" }}
                    canSearch={true}
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <SpringModal open={modalCreate} onClose={handleModalClose}>
        <MDBox mb={2}>
          <MDInput
            type="text"
            name="variable"
            value={createUser.variable}
            onChange={handleCreateChange}
            placeholder="Variable"
            label="Variable"
            fullWidth
          />
        </MDBox>

        <MDBox mb={2}>
          <MDInput
            type="text"
            name="valor"
            value={createUser.valor}
            onChange={handleCreateChange}
            placeholder="valor"
            label="valor"
            fullWidth
          />
        </MDBox>

        <MDBox mb={2}>
          <FormControl fullWidth>
            <InputLabel id="select-label">Status</InputLabel>
            <Select
              labelId="select-label"
              id="simple-select"
              type="select"
              name="status_id"
              value={createUser.status_id}
              onChange={handleCreateChange}
              placeholder="Status"
              label="Status"
              fullWidth
              style={{ padding: ".75rem" }}
            >
              <MenuItem value={1}>Activo</MenuItem>
              <MenuItem value={2}>Inactivo</MenuItem>
            </Select>
          </FormControl>
        </MDBox>

        <MDBox display={"flex"} justifyContent={"center"} sx={{ mt: 4 }}>
          <MDButton variant="gradient" color="secondary" onClick={handleCreateUser}>
            Guardar
          </MDButton>

          <MDButton variant="gradient" color="primary" onClick={handleModalClose} sx={{ ml: 2 }}>
            Cancelar
          </MDButton>
        </MDBox>
      </SpringModal>

      <SpringModal open={deleteModal} onClose={handleModalClose}>
        <MDTypography variant="h6" component="h2" textAlign={"center"}>
          ¿Estás seguro de que quieres deshabilitar {config?.variable}?
        </MDTypography>

        <MDBox display={"flex"} justifyContent={"center"} sx={{ mt: 4 }}>
          <MDButton variant="gradient" color="secondary" onClick={confirmDelete}>
            Confirmar
          </MDButton>

          <MDButton variant="gradient" color="primary" onClick={handleModalClose} sx={{ ml: 2 }}>
            Cancelar
          </MDButton>
        </MDBox>
      </SpringModal>

      {/* EDIT MODAL */}
      <SpringModal open={modalEdit} onClose={handleModalClose}>
        <MDBox mb={2}>
          <MDInput
            type="text"
            name="variable"
            value={dataForm.variable}
            onChange={handleFormChange}
            placeholder="Variable"
            label="Variable"
            fullWidth
          />
        </MDBox>

        <MDBox mb={2}>
          <MDInput
            type="text"
            name="value"
            value={dataForm.value}
            onChange={handleFormChange}
            placeholder="Valor"
            label="Valor"
            fullWidth
          />
        </MDBox>

        <MDBox mb={2}>
          <FormControl fullWidth>
            <InputLabel id="select-label">Status</InputLabel>
            <Select
              labelId="select-label"
              id="simple-select"
              type="select"
              name="status"
              value={dataForm.status}
              onChange={handleFormChange}
              placeholder="Status"
              label="Status"
              fullWidth
              style={{ padding: ".75rem" }}
            >
              {config?.data?.status.map((status) => {
                if (status.name !== "Eliminado") {
                  return (
                    <MenuItem key={status.id} value={status.id}>
                      {status.name}
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </FormControl>
        </MDBox>

        <MDBox display={"flex"} justifyContent={"center"} sx={{ mt: 4 }}>
          <MDButton variant="gradient" color="secondary" onClick={handleSubmit}>
            Guardar
          </MDButton>

          <MDButton variant="gradient" color="primary" onClick={handleModalClose} sx={{ ml: 2 }}>
            Cancelar
          </MDButton>
        </MDBox>
      </SpringModal>
    </DashboardLayout>
  );
}

export default TableConfig;
