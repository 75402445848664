/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

import { useMaterialUIController, fetchUsers } from "context";
import { useEffect, useState } from "react";
import { IconButton, Tooltip, Zoom } from "@mui/material";
import { Delete, Edit, PersonOff } from "@mui/icons-material";

export default function data({ handleDeleteClick, handleEditClick, handleDisableClick }) {
  const [controller, dispatch] = useMaterialUIController();
  const { users } = controller;

  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetchUsers(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (users?.data?.users) {
      const formattedRows = users?.data?.users
        .filter((user) => user?.status?.name !== "Eliminado")
        .map((user) => ({
          id: user?.id,
          user: user?.username,
          name: user?.person?.name,
          lastname: user?.person?.lastname,
          email: user?.person?.email,
          phone: user?.person?.phone_number,
          status: user?.status?.name,
          rol: user?.rol?.name,
          actions: user,
          searchableData: `${user?.person?.name} ${user?.person?.lastname} ${user?.person?.email}`,
        }));
      setRows(formattedRows);
    }
  }, [users]);

  const columns = [
    {
      Header: "id",
      accessor: "id",
      align: "left",
      width: "10%",
      Cell: ({ value }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {value}
          </MDTypography>
        </MDBox>
      ),
    },
    {
      Header: "usuario",
      accessor: "user",
      align: "left",
      Cell: ({ value }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {value}
          </MDTypography>
        </MDBox>
      ),
    },
    {
      Header: "datos del usuario",
      accessor: "searchableData",
      align: "left",
      Cell: ({ row }) => {
        const { name, lastname, email } = row.original;
        return (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDBox lineHeight={1}>
              <MDTypography display="block" variant="button" fontWeight="medium">
                {name} {lastname}
              </MDTypography>
              <MDTypography variant="caption">{email}</MDTypography>
            </MDBox>
          </MDBox>
        );
      },
    },
    {
      Header: "teléfono",
      accessor: "phone",
      width: "1%",
      align: "left",
      Cell: ({ value }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {value}
          </MDTypography>
        </MDBox>
      ),
    },
    {
      Header: "Rol",
      accessor: "rol",
      width: "1%",
      align: "left",
      Cell: ({ value }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {value}
          </MDTypography>
        </MDBox>
      ),
    },
    {
      Header: "status",
      accessor: "status",
      align: "center",
      Cell: ({ value }) => (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={value}
            color={value === "Activo" ? "success" : value === "Inactivo" ? "warning" : "error"}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
    },
    {
      Header: "acciones",
      accessor: "actions",
      align: "center",
      Cell: ({ value: user }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            style={{
              cursor: "pointer",
              transition: "all 0.3s ease",
            }}
            onClick={() => handleEditClick(user)}
          >
            <Tooltip
              title="Editar"
              placement="top"
              TransitionComponent={Zoom}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                },
              }}
            >
              <IconButton size="small">
                <Edit fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </MDTypography>

          <MDTypography
            component="a"
            variant="caption"
            color="text"
            fontWeight="medium"
            style={{
              cursor: "pointer",
              transition: "all 0.3s ease",
            }}
            onClick={() => handleDisableClick(user)}
          >
            <Tooltip
              title="Deshabilitar"
              placement="top"
              TransitionComponent={Zoom}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                },
              }}
            >
              <IconButton size="small">
                <PersonOff />
              </IconButton>
            </Tooltip>
          </MDTypography>

          <MDTypography
            component="a"
            variant="caption"
            color="text"
            fontWeight="medium"
            style={{
              cursor: "pointer",
              transition: "all 0.3s ease",
            }}
            onClick={() => handleDeleteClick(user)}
          >
            <Tooltip
              title="Eliminar"
              placement="top"
              TransitionComponent={Zoom}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                },
              }}
            >
              <IconButton size="small">
                <Delete />
              </IconButton>
            </Tooltip>
          </MDTypography>
        </MDBox>
      ),
    },
  ];

  return { columns, rows };
}
