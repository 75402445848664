import * as React from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSpring, animated } from "@react-spring/web";

const Fade = React.forwardRef(function Fade(props, ref) {
  const { children, in: open, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.node,
  in: PropTypes.bool,
};

const SpringModal = ({ open, onClose, children, width, bgcolor, boxShadow }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      // sx={{ zIndex: 1000001 }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: width || 400,
            bgcolor: bgcolor || "background.paper",
            boxShadow: boxShadow || 24,
            p: 4,
            borderRadius: "1rem",
          }}
        >
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};

SpringModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.arrayOf.isRequired,
  width: PropTypes.arrayOf.isRequired,
  bgcolor: PropTypes.arrayOf.isRequired,
  boxShadow: PropTypes.arrayOf.isRequired,
};

export default SpringModal;
