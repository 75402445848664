import { createContext, useContext, useReducer, useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { blueGrey } from "@mui/material/colors";
import axios from "axios";
import Cookies from "js-cookie";

// Material Dashboard 2 React main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// Material Dashboard 2 React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, blueGrey: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "GET_USERS": {
      return { ...state, users: action.value };
    }
    case "GET_TRADERS": {
      return { ...state, traders: action.value };
    }
    case "GET_CONFIG": {
      return { ...state, config: action.value };
    }
    case "GET_CHANNELS": {
      return { ...state, channels: action.value };
    }
    case "GET_PAYMENTS": {
      return { ...state, payments: action.value };
    }
    case "GET_SUBSCRIPTIONS": {
      return { ...state, subscriptions: action.value };
    }
    case "GET_HISTORIAL": {
      return { ...state, historial: action.value };
    }
    case "GET_METRICS": {
      return { ...state, metrics: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 React context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    darkMode: false,
    users: [],
    traders: [],
    config: [],
    channels: [],
    payments: [],
    subscriptions: [],
    historial: [],
    metrics: [],
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });

const fetchMetrics = async (dispatch) => {
  const token = Cookies.get("access_token");
  try {
    const response = await axios.get("/GetCount", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const metrics = response.data.data;

    dispatch({ type: "GET_METRICS", value: metrics });
  } catch (error) {
    console.error(error);
  }
};

const fetchUsers = async (dispatch) => {
  const token = Cookies.get("access_token");
  try {
    const response = await axios.get("/admin/users", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const users = response.data;

    dispatch({ type: "GET_USERS", value: users });
  } catch (error) {
    console.error(error);
  }
};

const fetchTraders = async (dispatch) => {
  const token = Cookies.get("access_token");
  try {
    const response = await axios.get("/admin/traders", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const traders = response.data;

    dispatch({ type: "GET_TRADERS", value: traders });
  } catch (error) {
    console.error(error);
  }
};

const fetchConfig = async (dispatch) => {
  const token = Cookies.get("access_token");
  try {
    const response = await axios.get("/admin/config", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const config = response.data;

    dispatch({ type: "GET_CONFIG", value: config });
  } catch (error) {
    console.error(error);
  }
};

const fetchChannels = async (dispatch) => {
  const token = Cookies.get("access_token");
  try {
    const response = await axios.get("/admin/bots", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const channels = response.data;

    dispatch({ type: "GET_CHANNELS", value: channels });
  } catch (error) {
    console.error(error);
  }
};

const fetchPayments = async (dispatch) => {
  const token = Cookies.get("access_token");
  try {
    const response = await axios.get("/admin/payments", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const payments = response.data;

    dispatch({ type: "GET_PAYMENTS", value: payments });
  } catch (error) {
    console.error(error);
  }
};

const fetchSubscriptions = async (dispatch) => {
  const token = Cookies.get("access_token");
  try {
    const response = await axios.get("/admin/subscriptions", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const subscriptions = response.data;

    dispatch({ type: "GET_SUBSCRIPTIONS", value: subscriptions });
  } catch (error) {
    console.error(error);
  }
};

const fetchHistorial = async (dispatch) => {
  const token = Cookies.get("access_token");
  try {
    const response = await axios.get("/admin/historial", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const historial = response.data;

    dispatch({ type: "GET_HISTORIAL", value: historial });
  } catch (error) {
    console.error(error);
  }
};

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  fetchUsers,
  fetchTraders,
  fetchConfig,
  fetchChannels,
  fetchPayments,
  fetchSubscriptions,
  fetchHistorial,
  fetchMetrics,
};
