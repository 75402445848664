import React, { useCallback, useState } from "react";
import Cookies from "js-cookie";
import { useMaterialUIController } from "context";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import usersTableData from "layouts/table_users/data/usersTableData";

// User components
import UserEdit from "./components/UserEdit";
import CreateUser from "./components/CreateUser";
import DisableUser from "./components/DisableUser";
import DeleteUser from "./components/DeleteUser";

function TableUsers() {
  const [controller] = useMaterialUIController();
  const rol = Cookies.get("user_p");

  const { users } = controller;

  // Variables de estado
  const [user, setUser] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalDisable, setModalDisable] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);

  const token = Cookies.get("access_token");
  const user_id = Cookies.get("user_id");

  const handleDeleteClick = (user) => {
    if (parseInt(user_id) === user.id) return;

    setUser(user);
    setModalDelete(true);
  };

  const handleDisableClick = (user) => {
    if (parseInt(user_id) === user.id) return;

    if (user?.status.name !== "Activo") return;
    setUser(user);
    setModalDisable(true);
  };

  const handleEditClick = (user) => {
    setUser(user);
    setModalEdit(true);
  };

  const { columns, rows } = usersTableData({
    handleDeleteClick,
    handleEditClick,
    handleDisableClick,
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Usuarios
                </MDTypography>
              </MDBox>

              {rol === "1" ? (
                ""
              ) : (
                <MDBox display="flex" justifyContent="flex-start" mx={2} mt={2} py={2} px={1}>
                  <MDButton color="primary" size="small" onClick={() => setModalCreate(true)}>
                    +
                  </MDButton>
                </MDBox>
              )}

              {rol === "1" ? (
                <MDBox fullWidth display="flex" justifyContent="center">
                  <MDTypography py={3}>No tienes permiso para ver este modulo</MDTypography>
                </MDBox>
              ) : (
                <MDBox pt={0}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20, 25] }}
                    showTotalEntries={false}
                    noEndBorder
                    pagination={{ variant: "gradient", color: "info" }}
                    canSearch={true}
                  />
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <DisableUser
        user={user}
        modalDisable={modalDisable}
        setModalDisable={setModalDisable}
        token={token}
      />

      <DeleteUser
        user={user}
        modalDelete={modalDelete}
        setModalDelete={setModalDelete}
        token={token}
      />

      <UserEdit
        user={user}
        users={users}
        modalEdit={modalEdit}
        setModalEdit={setModalEdit}
        handleEditClick={handleEditClick}
        token={token}
      />

      <CreateUser
        modalCreate={modalCreate}
        setModalCreate={setModalCreate}
        users={users}
        user={user}
        token={token}
      />
    </DashboardLayout>
  );
}

export default TableUsers;
