// Validación para nombres y apellidos
export const validateCharacters = (value) => {
  const regex = /^[A-Za-z\s]*$/;
  return regex.test(value);
};

// Validación para nombre
export const validateName = (name) => {
  if (name.length < 1) {
    return { error: "Debe ingresar un nombre." };
  } else if (name.length < 3) {
    return { error: "El nombre debe tener al menos 3 letras." };
  }

  if (!validateCharacters(name)) return;

  return { error: "" };
};

// Validación para apellido
export const validateLastName = (lastName) => {
  if (lastName.length < 1) {
    return { error: "Debe ingresar un apellido." };
  } else if (lastName.length < 3) {
    return { error: "El apellido debe tener al menos 3 letras." };
  }

  if (!validateCharacters(lastName)) return;

  return { error: "" };
};

// Validación para teléfono
export const validatePhoneNumber = (value) => {
  const regex = /^[+0-9]*$/;
  return regex.test(value);
};

export const validatePhone = (phone) => {
  if (phone.length < 1) {
    return { error: "Debe ingresar un número de teléfono." };
  }

  if (!validatePhoneNumber(phone)) {
    return;
  }

  return { error: "" };
};

// Validación para email
export const validateEmail = (email) => {
  const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  if (email && !regex.test(email)) {
    return { isValid: false, error: "Correo electrónico no válido." };
  }

  return { isValid: true, error: "" };
};

// Validación para usuario
export const validateUserCharacters = (value) => {
  if (value === "") return true;
  const regex = /^[A-Za-z0-9]+$/;
  return regex.test(value);
};

export const validateUser = (user) => {
  if (user.length < 1) {
    return { error: "Debe ingresar un nombre de usuario." };
  } else if (user.length < 3) {
    return { error: "El nombre de usuario debe tener al menos 3 letras." };
  }

  if (!validateUserCharacters(user)) return;

  return { error: "" };
};

export const validatePassword = (password) => {
  return {
    length: password.length >= 8 && password.length <= 16,
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
    number: /\d/.test(password),
    specialChar: /[\W_]/.test(password),
  };
};
