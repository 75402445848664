// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect } from "react";
import { useMaterialUIController } from "context";
import { fetchMetrics } from "context";

function Dashboard() {
  const [controller, dispatch] = useMaterialUIController();

  const { metrics } = controller;

  useEffect(() => {
    fetchMetrics(dispatch);
  }, [dispatch]);

  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "f2f467cb-29d1-4f50-a3a1-8bc416a8f85e";

    const script = document.createElement("script");
    script.src = "https://client.crisp.chat/l.js";
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const tradersActivosSA = parseInt(metrics?.traders?.Activos[0]?.total) || 0;
  const tradersInactivosSA = parseInt(metrics?.traders?.inactivos[0]?.total) || 0;
  const tradersActivosTV = parseInt(metrics?.traders?.Activos[1]?.total) || 0;
  const tradersInactivosTV = parseInt(metrics?.traders?.inactivos[1]?.total) || 0;
  const pagosManualesOK = parseInt(metrics?.pagosManuales?.Procesados[0]?.total) || 0;
  const pagosManualesNoOK = parseInt(metrics?.pagosManuales?.PorProcesar[0]?.total) || 0;
  const pagosAutomaticosOK = parseInt(metrics?.pagosAutomaticos?.Procesados[0]) || 0;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          {/**este es el puito cuadrito de booking*/}
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="card_membership "
                title="Trading en vivo"
                count={tradersActivosTV + tradersInactivosTV}
                userActive={{
                  color: "green",
                  label: "Usuarios Activos",
                  amount: tradersActivosTV,
                }}
                userInactive={{
                  color: "error",
                  label: "Usuarios Inactivos",
                  amount: tradersInactivosTV,
                }}
              />
            </MDBox>
          </Grid>
          {/**este es el puito cuadrito de todays user*/}
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Sala de Analisis"
                count={tradersActivosSA + tradersInactivosSA}
                userActive={{
                  color: "green",
                  label: "Usuarios Activos",
                  amount: tradersActivosSA,
                }}
                userInactive={{
                  color: "error",
                  label: "Usuarios Inactivos",
                  amount: tradersInactivosSA,
                }}
              />
            </MDBox>
          </Grid>
          {/**este es el puito cuadrito de Revenue*/}
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="person"
                title="Usuarios Totales"
                count={
                  tradersActivosSA + tradersActivosTV + tradersInactivosSA + tradersInactivosTV
                }
                userActive={{
                  color: "green",
                  label: "Total de Usuarios Activos",
                  amount: `${tradersActivosSA + tradersActivosTV}`,
                }}
                userInactive={{
                  color: "error",
                  label: "Total de Usuarios Inactivos",
                  amount: `${tradersInactivosSA + tradersInactivosTV}`,
                }}
              />
            </MDBox>
          </Grid>
        </Grid>

        <MDBox py={3}>
          <Grid container spacing={3}>
            {/**este es el puito cuadrito de booking*/}
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="payments"
                  title="Pagos Manuales"
                  count={pagosManualesOK + pagosManualesNoOK}
                  userActive={{
                    color: "green",
                    label: "Procesados",
                    amount: pagosManualesOK,
                  }}
                  userInactive={{
                    color: "error",
                    label: "Por Procesar",
                    amount: pagosManualesNoOK,
                  }}
                />
              </MDBox>
            </Grid>
            {/**este es el puito cuadrito de todays user*/}
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="credit_card"
                  title="Pagos Automáticos"
                  count={pagosAutomaticosOK}
                  userActive={{
                    color: "green",
                    label: "Procesados",
                    amount: pagosAutomaticosOK,
                  }}
                />
              </MDBox>
            </Grid>
            {/**este es el puito cuadrito de Revenue*/}
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="account_balance_wallet"
                  title="Pagos Totales"
                  count={pagosManualesOK + pagosManualesNoOK + pagosAutomaticosOK}
                  userActive={{
                    color: "green",
                    label: "Total de Pagos Procesados",
                    amount: `${pagosManualesOK + pagosAutomaticosOK}`,
                  }}
                  userInactive={{
                    color: "error",
                    label: "Total de Pagos Por Procesar",
                    amount: `${pagosManualesNoOK}`,
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}></Grid>
            <Grid item xs={12} md={6} lg={4}></Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
