// @mui material components
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

import typography from "assets/theme/base/typography";
import { useState } from "react";
import axios from "axios";
import Loading from "components/Loading";
import SpringModal from "examples/Modal";

function Cover() {
  const { size } = typography;

  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      const response = await axios.post("/RecoveryPass", { email: email });

      if (response.status === 200) {
        setIsLoading(false);
        setOpen(true);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    window.location.href = "/authentication/sign-in";
  };

  return (
    <CoverLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={3}
          px={2}
          textAlign="center"
        >
          <MDTypography variant="h5" fontWeight="medium" color="white" mt={0}>
            Restablecer Contraseña
          </MDTypography>
          <MDTypography
            display="block"
            variant="button"
            color="white"
            my={1}
            sx={{ fontSize: ".8rem" }}
          >
            Recibirás un correo electrónico en máximo 60 segundos.
          </MDTypography>
        </MDBox>
        <MDBox pt={5} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDTypography variant="button" fontWeight="medium">
              &nbsp;Ingrese su correo&nbsp;
            </MDTypography>

            <MDBox mb={4} mt={2}>
              <MDInput
                type="email"
                label="Correo Electrónico"
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {!isLoading ? (
                  "Restablecer"
                ) : (
                  <Loading size={20} borderWidth={2} borderColor={"white"} margin={0} />
                )}
              </MDButton>
            </MDBox>
            <MDBox display="flex" alignItems="center" mt={2} px={0.5} fontSize={size.sm}>
              Volver a
              <Link href="/authentication/sign-in">
                <MDTypography variant="button" fontWeight="medium">
                  &nbsp;Iniciar Sesión&nbsp;
                </MDTypography>
              </Link>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>

      <SpringModal open={open} onClose={handleClose}>
        <MDBox display="grid" alignItems="center" justifyContent="center">
          <MDTypography align="center" variant="h4" fontWeight="medium" mb={3}>
            Contraseña Restablecida
          </MDTypography>
          <MDTypography variant="button" fontWeight="light" verticalAlign="middle" align="center">
            Se envió al correo{" "}
            <MDTypography variant="button" fontWeight="medium">
              {email}
            </MDTypography>{" "}
            su nueva contraseña
          </MDTypography>
          <MDButton color="info" fullWidth onClick={handleClose} sx={{ marginTop: "2rem" }}>
            Cerrar
          </MDButton>
        </MDBox>
      </SpringModal>
    </CoverLayout>
  );
}

export default Cover;
