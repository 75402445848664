/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

import { useMaterialUIController, fetchSubscriptions } from "context";
import { useEffect, useState } from "react";
import Icon from "assets/iconos";
import { IconButton, Tooltip, Zoom } from "@mui/material";
import { Edit, PersonOff } from "@mui/icons-material";

export default function data({ handleDeleteClick, handleEditClick, handleProofPayment }) {
  const [controller, dispatch] = useMaterialUIController();
  const { subscriptions } = controller;

  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetchSubscriptions(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (subscriptions) {
      const formattedRows = subscriptions?.data?.subscriptions.map((s) => ({
        id: s?.id,
        name: s?.name,
        subscription_name: s?.subscription_name,
        subscription_price: s?.price,
        subscription_currency: s?.currency,
        bot: s?.bot?.name,

        status: s?.status?.name,
        actions: s,
      }));

      setRows(formattedRows);
    }
  }, [subscriptions]);

  const columns = [
    {
      Header: "id",
      accessor: "id",
      align: "left",
      width: "10%",
      Cell: ({ value }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {value}
          </MDTypography>
        </MDBox>
      ),
    },
    {
      Header: "nombre",
      accessor: "name",
      align: "left",
      width: "10%",
      Cell: ({ value }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {value}
          </MDTypography>
        </MDBox>
      ),
    },
    {
      Header: "Precio",
      accessor: "subscription_price",
      align: "left",
      Cell: ({ value }) => {
        return (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDBox lineHeight={1}>
              <MDTypography variant="caption">$ {value}</MDTypography>
            </MDBox>
          </MDBox>
        );
      },
    },
    {
      Header: "Divisa",
      accessor: "subscription_currency",
      align: "center",
      Cell: ({ value }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDBox lineHeight={1}>
            <MDTypography variant="caption">{value}</MDTypography>
          </MDBox>
        </MDBox>
      ),
    },
    {
      Header: "Bot",
      accessor: "bot",
      align: "center",
      Cell: ({ value }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDBox lineHeight={1}>
            <MDTypography variant="caption">{value}</MDTypography>
          </MDBox>
        </MDBox>
      ),
    },

    {
      Header: "status",
      accessor: "status",
      align: "center",
      Cell: ({ value }) => (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={value}
            color={value === "Activo" ? "success" : value === "Inactivo" ? "warning" : "error"}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
    },
    {
      Header: "acciones",
      accessor: "actions",
      align: "center",
      Cell: ({ value: subs }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <Tooltip
            title="Editar"
            placement="top"
            TransitionComponent={Zoom}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -10],
                    },
                  },
                ],
              },
            }}
          >
            <IconButton size="small" onClick={() => handleEditClick(subs)}>
              <Edit />
            </IconButton>
          </Tooltip>
        </MDBox>
      ),
    },
  ];

  return { columns, rows };
}
