import axios from "axios";
import Cookies from "js-cookie";

// Función para cerrar sesión
export const logout = async () => {
  //   try {
  //     const respones = await axios.post("/logout");

  // } catch (error) {
  //     console.error("Error al cerrar sesión:", error);
  // }
  Cookies.remove("access_token");
  Cookies.remove("username");
  Cookies.remove("user_p");
  Cookies.remove("user_id");

  window.location.href = "/authentication/sign-in";
};
