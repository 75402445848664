import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MDBox from "components/MDBox";
import Sidenav from "examples/Sidenav";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import { routes, authRoutes } from "routes";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import brandWhite from "assets/LOGOTIPOS/BLANCO.png";
import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";
import axios from "axios";
import SpringModal from "examples/Modal";
import Cookies from "js-cookie";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { FormHelperText, List, ListItem, ListItemText } from "@mui/material";
import { validatePassword } from "utils/validations";

// axios.defaults.baseURL = "http://localhost:8000/api";
axios.defaults.baseURL = "https://api.sistemasactraders.com/api";

export default function App() {
  const [changePass, setChangePass] = useState(false);
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [passError, setPassError] = useState("");
  const [passwordRequirements, setPasswordRequirements] = useState({
    hasLength: false,
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
    hasSpecialChar: false,
  });
  const [isTypingPassword, setIsTypingPassword] = useState(false);

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const isAuthRoute = pathname.startsWith("/authentication");

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const pass = Cookies.get("cPass");
  const user_id = Cookies.get("user_id");
  const token = Cookies.get("access_token");

  useEffect(() => {
    if (pass === "1") {
      setChangePass(true);
    }
  }, [pass]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={
              route.protected ? <ProtectedRoute>{route.component}</ProtectedRoute> : route.component
            }
            key={route.key}
          />
        );
      }

      return null;
    });

  const handleNewPassChange = (e) => {
    const { value } = e.target;
    const requirements = validatePassword(value);
    setPasswordRequirements(requirements);

    // Mostrar los errores mientras se escribe
    setIsTypingPassword(true);

    const allValid = Object.values(requirements).every(Boolean);
    setPassError(allValid ? "" : "La contraseña no cumple con los requisitos.");
    setNewPass(value);

    // Si se cumplen todos los requisitos, desactiva el estado de escritura
    if (allValid) {
      setIsTypingPassword(false);
    }
  };

  const handleConfirmPassChange = (e) => setConfirmPass(e.target.value);

  const handleChangePassword = async () => {
    if (newPass !== confirmPass) {
      setPassError("Las contraseñas no coinciden");
      return;
    }

    if (Object.values(passwordRequirements).some((req) => !req)) {
      setPassError("La contraseña no cumple con los requisitos.");
      return;
    }

    try {
      const response = await axios.post(
        "/ChangePassword",
        {
          id: parseInt(user_id),
          password: newPass,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setChangePass(false);
      Cookies.remove("cPass");
    } catch (error) {
      console.error(error);
    }
    setPassError("");
  };

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && !isAuthRoute && (
        <Sidenav
          color={sidenavColor}
          brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
          brandName="Material Dashboard 2"
          routes={routes}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}

      <Routes>
        {getRoutes(routes)}
        {getRoutes(authRoutes)}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>

      <SpringModal open={changePass}>
        <MDTypography>Cambiar Contraseña</MDTypography>
        <MDTypography variant="h6" fontWeight="light" mb={2}>
          Por favor ingrese su nueva contraseña
        </MDTypography>
        <MDBox mb={2}>
          <MDInput
            type="password"
            label="Nueva Contraseña"
            fullWidth
            value={newPass}
            onChange={handleNewPassChange}
          />
          {passError && <FormHelperText error>{passError}</FormHelperText>}
          {isTypingPassword && !Object.values(passwordRequirements).every(Boolean) && (
            <List dense sx={{ paddingLeft: ".5rem" }}>
              <ListItem>
                <ListItemText
                  primary={
                    <MDTypography
                      variant="caption"
                      style={{
                        color: passwordRequirements.length ? "green" : "red",
                      }}
                    >
                      Mínimo 8 y máximo 16 caracteres
                    </MDTypography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <MDTypography
                      variant="caption"
                      style={{
                        color: passwordRequirements.uppercase ? "green" : "red",
                      }}
                    >
                      Al menos una letra mayúscula
                    </MDTypography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <MDTypography
                      variant="caption"
                      style={{
                        color: passwordRequirements.lowercase ? "green" : "red",
                      }}
                    >
                      Al menos una letra minúscula
                    </MDTypography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <MDTypography
                      variant="caption"
                      style={{
                        color: passwordRequirements.number ? "green" : "red",
                      }}
                    >
                      Al menos un número
                    </MDTypography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <MDTypography
                      variant="caption"
                      style={{
                        color: passwordRequirements.specialChar ? "green" : "red",
                      }}
                    >
                      Al menos un carácter especial
                    </MDTypography>
                  }
                />
              </ListItem>
            </List>
          )}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="password"
            label="Repita Contraseña"
            fullWidth
            value={confirmPass}
            onChange={handleConfirmPassChange}
          />
        </MDBox>
        {passError && (
          <MDBox mb={2}>
            <FormHelperText error>{passError}</FormHelperText>
          </MDBox>
        )}
        <MDBox>
          <MDButton variant="outlined" color="secondary" fullWidth onClick={handleChangePassword}>
            Cambiar contraseña
          </MDButton>
        </MDBox>
      </SpringModal>
    </ThemeProvider>
  );
}
