/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import { useEffect, useState } from "react";
import { useMaterialUIController, fetchConfig } from "context";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

import Icon from "assets/iconos";
import { Edit, PersonOff } from "@mui/icons-material";
import { IconButton, Tooltip, Zoom } from "@mui/material";

export default function data(handleDeleteClick, handleEditClick) {
  const [controller, dispatch] = useMaterialUIController();
  const { config } = controller;
  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetchConfig(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (config) {
      const formattedRows = config?.data?.config.map((c) => ({
        id: c?.id,
        variable: c?.variable,
        value: `${c?.valor}`,
        status: c?.status?.name,
        actions: c,
      }));
      setRows(formattedRows);
    }
  }, [config]);

  const columns = [
    {
      Header: "id",
      accessor: "id",
      align: "left",
      width: "10%",
      Cell: ({ value }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {value}
          </MDTypography>
        </MDBox>
      ),
    },
    {
      Header: "variable",
      accessor: "variable",
      align: "left",
      Cell: ({ value }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {value}
          </MDTypography>
        </MDBox>
      ),
    },
    {
      Header: "valor",
      accessor: "value",
      align: "left",
      Cell: ({ value }) => {
        return (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDBox lineHeight={1}>
              <MDTypography display="block" variant="button" fontWeight="medium">
                {value}
              </MDTypography>
            </MDBox>
          </MDBox>
        );
      },
    },
    {
      Header: "status",
      accessor: "status",
      align: "center",
      Cell: ({ value }) => (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={value}
            color={value === "Activo" ? "success" : value === "Inactivo" ? "warning" : "error"}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
    },
    {
      Header: "acciones",
      accessor: "actions",
      align: "center",
      Cell: ({ value: config }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <Tooltip
            title="Editar"
            placement="top"
            TransitionComponent={Zoom}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -10],
                    },
                  },
                ],
              },
            }}
          >
            <IconButton size="small" onClick={() => handleEditClick(config)}>
              <Edit />
            </IconButton>
          </Tooltip>

          <Tooltip
            title="deshabilitar"
            placement="top"
            TransitionComponent={Zoom}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -10],
                    },
                  },
                ],
              },
            }}
          >
            <IconButton size="small" onClick={() => handleDeleteClick(config)}>
              <PersonOff />
            </IconButton>
          </Tooltip>
        </MDBox>
      ),
    },
  ];

  return { columns, rows };
}
