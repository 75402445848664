import React, { useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SpringModal from "examples/Modal";
import PropTypes from "prop-types";
import axios from "axios";
import { useMaterialUIController, fetchUsers } from "context";
import MDSnackbar from "components/MDSnackbar";

const DisableUser = ({ user, modalDisable, setModalDisable, token }) => {
  const [controller, dispatch] = useMaterialUIController();

  const [snackbar, setSnackbar] = useState({
    open: false,
    color: "",
    icon: "",
    title: "",
    content: "",
  });

  const handleDisable = async () => {
    try {
      const response = await axios.put(
        `/admin/users/${user.id}`,
        {
          name: user.person.name,
          lastname: user.person.lastname,
          phone_number: user.person.phone_number,
          email: user.person.email,
          status: 2,
          rol_id: user.rol.id,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        fetchUsers(dispatch);
        handleModalClose();

        //Muestra mensaje si se crea el usuario
        setSnackbar({
          open: true,
          icon: "check",
          color: "success",
          title: "Usuario Deshabilitado!",
          content: "Usuario se deshabilito con exito!",
        });
      }
    } catch (error) {
      console.error(error);

      // Muestra un mensaje de error si el usuario no tiene permisos
      if (error.response.status === 403) {
        setSnackbar({
          open: true,
          icon: "warning",
          color: "error",
          title: "Lo siento!",
          content: "No estas autorizado para realizar esta acción!",
        });
      }
    }
  };

  const handleModalClose = () => {
    setModalDisable(false);
  };

  return (
    <>
      <SpringModal open={modalDisable} onClose={handleModalClose}>
        <MDTypography variant="h6" component="h2" textAlign={"center"}>
          ¿Estás seguro de que quieres deshabilitar al usuario {user?.username}?
        </MDTypography>

        <MDBox display={"flex"} justifyContent={"center"} sx={{ mt: 4 }}>
          <MDButton variant="outlined" color="secondary" onClick={handleDisable}>
            Confirmar
          </MDButton>

          <MDButton variant="outlined" color="primary" onClick={handleModalClose} sx={{ ml: 2 }}>
            Cancelar
          </MDButton>
        </MDBox>
      </SpringModal>

      <MDSnackbar
        color={snackbar.color}
        icon={snackbar.icon}
        title={snackbar.title}
        content={snackbar.content}
        open={snackbar.open}
        close={() => setSnackbar({ ...snackbar, open: false })}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </>
  );
};

DisableUser.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    email: PropTypes.string,
    person: PropTypes.shape({
      name: PropTypes.string,
      lastname: PropTypes.string,
      email: PropTypes.string,
      phone_number: PropTypes.string,
    }),
    status: PropTypes.shape({
      name: PropTypes.string,
    }),
    rol: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
  users: PropTypes.shape({
    data: PropTypes.shape({
      status: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        })
      ),
      roles: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        })
      ),
    }),
  }),
  modalDisable: PropTypes.func.isRequired,
  setModalDisable: PropTypes.func.isRequired,
  token: PropTypes.string,
};

export default DisableUser;
