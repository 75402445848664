import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const Loading = ({
  size = 48,
  borderWidth = 5,
  borderColor = "#000000",
  borderBottomColor = "#ff3d00",
  animationDuration = "1s",
  margin = "2rem",
}) => {
  const loaderStyle = {
    width: `${size}px`,
    height: `${size}px`,
    border: `${borderWidth}px solid ${borderColor}`,
    borderBottomColor: borderBottomColor,
    borderRadius: "50%",
    animation: `rotation ${animationDuration} linear infinite`,
    margin: `${margin}`,
  };

  return (
    <div className="loader-container">
      <span className="loader" style={loaderStyle}></span>
    </div>
  );
};

// Definición de las PropTypes
Loading.propTypes = {
  size: PropTypes.number,
  borderWidth: PropTypes.number,
  borderColor: PropTypes.string,
  borderBottomColor: PropTypes.string,
  animationDuration: PropTypes.string,
  margin: PropTypes.string,
};

export default Loading;
