import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Link } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpg";
import brandWhite from "assets/LOGOTIPOS/BLANCO.png";

// Axios
import axios from "axios";

// Cookies
import Cookies from "js-cookie";
import Loading from "components/Loading";
import MDSnackbar from "components/MDSnackbar";

function Basic() {
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    color: "",
    icon: "",
    title: "",
    content: "",
  });

  const handleChange = (e) => {
    setLoginData(() => {
      const { name, value } = e.target;
      return {
        ...loginData,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { data } = await axios.post("/login", {
        username: loginData.username,
        password: loginData.password,
      });

      if (data.data.user.changePassword) {
        Cookies.set("cPass", data.data.user.changePassword);
      }

      if (data.data.access_token) {
        Cookies.set("access_token", data.data.access_token, {
          expires: data.data.expires_in / 86400,
        });

        Cookies.set("user_id", data.data.user.id, {
          expires: data.data.expires_in / 86400,
        });

        Cookies.set("user_p", data.data.user.rol.id);

        Cookies.set("username", data.data.user.username, {
          expires: data.data.expires_in / 86400,
        });
        window.location.href = "/dashboard";
      }
    } catch (error) {
      if (error.response.status === 400) {
        setSnackbar({
          open: true,
          icon: "warning",
          color: "error",
          title: "Error!",
          content: `${error.response.data.message}`,
        });
      }

      setIsLoading(false);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            <Grid item justifyContent="center" alignItems="center" style={{ display: "flex" }}>
              <img src={brandWhite} alt="" width={"80%"} />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Usuario"
                fullWidth
                name="username"
                value={loginData.username}
                onChange={handleChange}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Contraseña"
                fullWidth
                name="password"
                value={loginData.password}
                onChange={handleChange}
              />
            </MDBox>
            <MDBox display="flex" justifyContent="flex-end" mt={3}>
              <Link href="/authentication/reset-password">
                <MDTypography variant="button" fontWeight="medium">
                  &nbsp;¿Has olvidado tu contraseña?&nbsp;
                </MDTypography>
              </Link>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {!isLoading ? (
                  "Iniciar sesión"
                ) : (
                  <Loading size={20} borderWidth={2} borderColor={"white"} margin={0} />
                )}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>

      <MDSnackbar
        color={snackbar.color}
        icon={snackbar.icon}
        title={snackbar.title}
        content={snackbar.content}
        open={snackbar.open}
        close={() => setSnackbar({ ...snackbar, open: false })}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </BasicLayout>
  );
}

export default Basic;
