/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useMaterialUIController, fetchHistorial } from "context";
import { useEffect, useState } from "react";

export default function data() {
  const [controller, dispatch] = useMaterialUIController();
  const { historial } = controller;
  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetchHistorial(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (historial.length !== 0) {
      const formattedRows = historial?.data
        ?.sort((a, b) => b.id - a.id)
        .map((h) => ({
          id: h?.id,
          action: h?.action,
          user: h?.user?.username,
          date: h?.created_at,
        }));

      setRows(formattedRows);
    }
  }, [historial]);

  const columns = [
    {
      Header: "id",
      accessor: "id",
      align: "left",
      width: "10%",
      Cell: ({ value }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {value}
          </MDTypography>
        </MDBox>
      ),
    },
    {
      Header: "action",
      accessor: "action",
      align: "left",
      Cell: ({ value }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {value}
          </MDTypography>
        </MDBox>
      ),
    },
    {
      Header: "Usuario",
      accessor: "user",
      align: "left",
      Cell: ({ value }) => {
        return (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDBox lineHeight={1}>
              <MDTypography variant="caption">{value}</MDTypography>
            </MDBox>
          </MDBox>
        );
      },
    },
    {
      Header: "Fecha",
      accessor: "date",
      align: "left",
      Cell: ({ value }) => {
        let date = new Date(value);
        return (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDBox lineHeight={1}>
              <MDTypography variant="caption">{date.toLocaleString()}</MDTypography>
            </MDBox>
          </MDBox>
        );
      },
    },
  ];

  return { columns, rows };
}
